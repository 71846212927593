.main-layout {
  display: grid;
  grid-template: "aside main" 1fr / 355px 1fr;
  min-height: 100vh;
}

.main-aside {
  grid-area: aside;
}

.main-content {
  grid-area: main;
}