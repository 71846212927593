$neutral-palette: 
  "neutral-darkest-color",
  "neutral-dark-color",
  "neutral-medium-color",
  "neutral-light-color",
  "neutral-lightest-color";

$ui-palette: (
  "brand-color": #0385BD,
  "deep-positive": #288C45,
  "positive": #66BD22,
  "neutral": #FFD600,
  "negative": #FF480E,
  "deep-negative": #BD0006,
  "grey": #E8E8E8
)