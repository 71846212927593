@each $key in $neutral-palette {
  .text-#{$key} {
      color: var(--#{$key});
  }
}

@each $key, $value in $ui-palette {
  .text-#{$key} {
      color: $value;
  }
}