.ant-tabs-ink-bar {
  display: none;
}
:where(.css-dev-only-do-not-override-11xg00t).ant-tabs-top
  > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-11xg00t).ant-tabs-bottom
  > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-11xg00t).ant-tabs-top
  > div
  > .ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-11xg00t).ant-tabs-bottom
  > div
  > .ant-tabs-nav::before {
  content: none;
}

:where(
    .css-dev-only-do-not-override-11xg00t
  ).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-dev-only-do-not-override-11xg00t).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-inline,
:where(
    .css-dev-only-do-not-override-11xg00t
  ).ant-menu-light.ant-menu-root.ant-menu-vertical,
:where(.css-dev-only-do-not-override-11xg00t).ant-menu-light
  > .ant-menu.ant-menu-root.ant-menu-vertical {
  border: none;
}

.display {
  &__flex {
    display: flex;
  }
}

.flex-direction {
  &__column {
    flex-direction: column;
  }
  &__row {
    flex-direction: row;
  }
}

.align-items {
  &__center {
    align-items: center;
  }
}

.justify-content {
  &__center {
    justify-content: center;
  }
  &__justifyContent {
    justify-content: "space-between";
  }
}

.mb-10 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}
