/* Main layout styles for all devices */
.main-layout {
    display: flex;
    height: 100%;
    width: 100%;
  }

.main-content {
    padding-left: 20px;
    padding-top: 40px;
    width: 100%;
}