.image-placeholder {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 218px;
  padding: 24px;
  border: var(--g-form-control-border);
  border-radius: 4px;
}

.image-placeholder-circle {
  width: 144px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #F2F2F2;
}