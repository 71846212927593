.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-56 {
  margin-top: 56px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-56 {
  margin-bottom: 56px;
}

.ml-8 {
  margin-left: 8px;
}