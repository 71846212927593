.pin-number {
  font-size: 16px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  &.positive {
    color: white;
    background-color: #66bd22;
  }

  &.negative {
    color: white;
    background-color: #ff480e;
  }

  &.neutral {
    color: black;
    background-color: #ffd600;
  }
}
