.auth-grid {
  display: grid;
  grid-template: "form aside" 1fr
                 "footer footer" 144px / auto 41%;
  height: 100%;

  @include breakpoint(1024) {
    display: block;
  }
}

.auth-aside {
  grid-area: aside;
}

.auth-dialog-container {
  grid-area: form;
}

.auth-dialog-footer {
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 36px 16px;

  .auth-dialog-footer-inner {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: 1164px;

    @include breakpoint(1024) {
      flex-direction: column;
    }
  }

  p {
    margin: 0;
  }
}