$breakpoints: (
  480: 480px,
  768: 768px,
  1024: 1024px,
);

// Here's an example of how to use the mixin to add a media query:
// @include breakpoint(768) { }
// If you need to use more advanced media queries, you can pass an additional
// $type argument to the mixin as well. For example:
// @include breakpoint(1024, "not all") { }

@mixin breakpoint($breakpoint, $type: "screen") {
  @media #{$type} and (max-width: map-get($map: $breakpoints, $key: $breakpoint)) {
    @content;
  }
}