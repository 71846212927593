.reactions-grid {
  display: flex;
  gap: 16px;

  .reactions-grid-item {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 136px;
    border-radius: 4px;
    background-color: var(--g-board-background);

    .emoji-icon {
      font-size: clamp(24px, 4vw, 48px);
      line-height: 1;
    }
  }
}