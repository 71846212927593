.main-aside {
  padding: 24px 20px;
  border-right: 1px solid var(--g-devider-color);
  background-color: white;
}

.main-aside-logo {
  margin-bottom: 16px;
}

.main-aside-user {
  margin-bottom: 24px;
}

.main-aside-user-media {
  display: flex;
  gap: 10px;

  img {
    align-self: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  .icon-button {
    align-self: center;
    margin-left: auto;
  }
}

.main-aside-user-media-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.main-aside-user-media-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.main-aside-user-media-subtitle {
  font-size: 14px;
  line-height: 1.26;
  font-weight: 400;
  color: var(--neutral-dark-color);
}

.main-aside-navigation {
  list-style-type: none;
  margin: 24px 0;
  padding-left: 24px;

  li {
    --navigation-item-color: var(--g-primary-color);

    display: flex;
    align-items: center;
    min-height: 40px;
    margin-bottom: 8px;
    border-radius: 8px;

    &.is-active {
      --navigation-item-color: var(--g-accent-color);

      background-color: #E5F6FD;
    }

    a {
      color: var(--navigation-item-color);
      text-decoration: none;
    }
  }
}