.auth-dialog {
  width: 100%;
  max-width: 572px;
  padding: 36px 56px 56px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);

  @include breakpoint(480) {
    padding: 36px 16px 56px;
  }
}