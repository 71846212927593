.stacked-bar-graph {
  --stacked-bar-graph-height: 40px;

  color: var(--neutral-darkest-color);
  display: flex;
  height: var(--stacked-bar-graph-height);

  :first-child {
    border-radius: calc(var(--stacked-bar-graph-height) / 2) 0 0 calc(var(--stacked-bar-graph-height) / 2);
  }

  :last-child {
    border-radius: 0 calc(var(--stacked-bar-graph-height) / 2) calc(var(--stacked-bar-graph-height) / 2) 0;
  }
}

.stacked-bar-graph-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-block: 8px;
  padding-inline: 16px;
  cursor: pointer;
}