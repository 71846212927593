.filters {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  padding-block: 0 24px;
  padding-inline: var(--g-layout-padding-inline);
  background-color: var(--g-board-background);

  form {
    display: flex;
    gap: 16px;
  }

  .ant-form-item {
    --ant-form-item-margin-bottom: 0;

    min-width: 320px;
  }
}