.auth-aside {
  --underline-height: 63px;

  display: flex;
  justify-content: flex-end;
  padding-block: var(--g-auth-layout-padding-top) clamp(24px, 3.9vw, 56px);
  padding-inline: clamp(24px, 3.9vw, 56px);
  background-color: #FFFFFF;
}

.tagline {
  font-size: clamp(33px, 3.4vw, 48px);
  line-height: 1.25;
  color: var(--g-primary-brand-color);
}

.text {
  font-size: clamp(18px, 2vw, 28px);
  line-height: 1.25;
  color: var(--g-primary-brand-color);
  margin-top: var(--underline-height);
  margin-bottom: clamp(60px, 8.4vw, 120px);
}

.brand-text-underline {
  color: var(--g-secondary-brand-color);
  font-weight: 700;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -55px;
    right: 0;
    width: 130%;
    height: var(--underline-height);
    background-image: url('../../svg/underline.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}