.sentiment-by-question {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 16px;
  max-height: 300px;
  overflow: auto;
  position: relative;
}

.column-scale {
  color: var(--neutral-dark-color);
  grid-column: 2;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  background: var(--g-board-background);
  position: sticky;
  bottom: 0;
}