.heatmap-image {
  --heatmap-image-padding-block: 32px;
  --heatmap-image-padding-inline: 24px;

  padding-block: var(--heatmap-image-padding-block);
  padding-inline: var(--heatmap-image-padding-inline);
  border-top: 1px solid var(--g-devider-color);
  border-bottom: 1px solid var(--g-devider-color);
  position: relative;

  .actions-container {
    display: flex;
    gap: 16px;
    // position: absolute;

    &.top-left {
      top: calc(var(--heatmap-image-padding-block) + 20px);
      left: calc(var(--heatmap-image-padding-inline) + 20px);
    }

    &.top-right {
      top: calc(var(--heatmap-image-padding-block) + 20px);
      right: calc(var(--heatmap-image-padding-inline) + 20px);
    }
  }
}
