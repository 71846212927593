.board {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: var(--g-board-background);

  &.full-width-body .board-body {
    --g-board-padding-inline: 0;
  }

  &.full-width {
    flex-basis: 100%;
  }
}

.board-body {
  flex: 1;
  padding-block: 32px;
  padding-inline: var(--g-board-padding-inline);
}

.board-header {
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  padding-block: 42px 0;
  padding-inline: var(--g-board-padding-inline);
}

.board-group {
  display: flex;
  flex: 1 0 100%;
  gap: var(--g-layout-padding-inline);
}

.board-set {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding-block: 25px;
  padding-inline: var(--g-layout-padding-inline);

  + .board-set {
    padding-top: 0;
  }
}

.board-content-alignfull {
  margin-inline: calc(var(--g-board-padding-inline) * -1);
}