.vl-badge.default {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 32px;
  padding-inline: 12px;
  border-radius: 4px;
}

.vl-badge-group {
  display: flex;
  gap: 8px;
}

.vl-badge.default {
  @each $name, $color in $ui-palette {
    &.#{$name} {
      background-color: transparentize($color, 0.45%);
    }
  }
}