.css-var-r0 {
  --ant-font-family: var(--g-font-family);
  --ant-font-size: var(--g-font-size);
  --ant-line-height: 1.5;
  --ant-color-text: var(--g-primary-color);
  --ant-control-height: var(--g-form-control-height);
  --ant-color-border: var(--g-devider-color);
  --ant-border-radius: var(--g-form-control-border-radius);
  --ant-color-primary: #E5F6FD;
  --ant-color-primary-hover: var(--g-accent-color);
  --ant-color-error: var(--g-error-color);
  --ant-color-warning: var(--g-warning-color);
  --ant-color-bg-container-disabled: var(--neutral-lightest-color);
  --ant-color-text-disabled: var(--g-primary-color);
  --ant-color-text-quaternary: var(--g-primary-color);
  --ant-control-height-sm: 32px;

  &.ant-btn {
    --ant-button-content-font-size: var(--g-font-size);
    --ant-button-primary-color: var(--g-accent-color);
    --ant-button-default-hover-color: var(--g-primary-color);
    --ant-button-default-hover-bg: rgba(229, 246, 253, 0.25);
    --ant-button-default-hover-border-color: #BEE9FA;
    --ant-button-default-active-border-color: var(--g-accent-color);
    --ant-button-default-active-color: var(--g-primary-color);
    --ant-button-default-bg: transparent;
    --ant-button-primary-shadow: none;
    --ant-button-default-shadow: none;
    --ant-color-primary-active: #BEE9FA;
    --ant-button-border-color-disabled: #F3F3F3;
    --ant-color-text-disabled: var(--neutral-dark-color);
    --ant-color-bg-container-disabled: #F3F3F3;
    --ant-color-text-disabled: var(--neutral-dark-color);
    --ant-button-padding-inline: 24px;
    --ant-button-padding-inline-sm: 16px;
    --ant-button-default-border-color: var(--g-devider-color);
  }

  &.ant-table-css-var {
    --ant-table-cell-font-size: 16px;
    --ant-table-header-bg: #FFFFFF;
    --ant-table-header-color: var(--g-primary-color);
    --ant-table-cell-padding-block: 12px;
    --ant-table-cell-padding-inline: 24px;
    --ant-table-header-border-radius: 0;
    --ant-table-header-split-color: transparent;
    --ant-table-border-color: var(--g-devider-color);
  }

  &.ant-tabs-css-var {
    --ant-tabs-item-selected-color: var(--g-accent-color);
    --ant-tabs-item-color: var(--neutral-darkest-color);
    --ant-tabs-item-hover-color: var(--g-accent-color);
  }

  &.ant-collapse {
    --ant-collapse-header-padding: 12px 12px 12px 24px;
    --ant-collapse-header-bg: transparent;
  }
}

// Buttons
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled) {
  &:hover {
    color: var(--g-accent-color);
    background: #D4F0FB;
  }

  &:active {
    color: var(--g-accent-color);
  }
}

.ant-btn-default:disabled, .ant-btn-default.ant-btn-disabled {
  --ant-button-border-color-disabled: var(--g-devider-color);
  --ant-color-bg-container-disabled: transparent;
}


// Table
.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  font-size: 18px;
  border-top: var(--ant-line-width) var(--ant-line-type) var(--ant-table-border-color);
}

// Tabs
.ant-tabs-nav {
  padding-block: 0 24px;
  padding-inline: var(--g-layout-padding-inline);
  background-color: var(--g-board-background);
}

.ant-tabs .ant-tabs-tab-btn {
  font-weight: 700;
}

.ant-tabs-top >.ant-tabs-nav::before, .ant-tabs-bottom >.ant-tabs-nav::before, .ant-tabs-top >div>.ant-tabs-nav::before, .ant-tabs-bottom >div>.ant-tabs-nav::before {
  border-bottom: 0 none;
}

.ant-tabs-tab.ant-tabs-tab-active {
  box-shadow: inset 0 -4px 0 -1px var(--g-accent-color);
}

.ant-tabs.tabs-as-buttons {
  --ant-tabs-horizontal-margin: 0;
  --ant-tabs-horizontal-item-gutter: 16px;

  .ant-tabs-tab {
    font-size: var(--g-font-size);
    font-weight: 400;
    color: var(--g-primary-color);
    height: var(--ant-control-height);
    padding-block: 8px;
    padding-inline: 24px;
    border: 1px solid var(--g-devider-color);
    border-radius: var(--ant-border-radius);

    &:hover {
      border-color: #BEE9FA;
      background: rgba(229, 246, 253, 0.25);
    }

    &.ant-tabs-tab-active {
      background-color: #BEE9FA;
      border: 1px solid #BEE9FA;
      box-shadow: none;
    }

    .ant-tabs-tab-btn {
      font-weight: normal;
    }
  }
}

// Dropdawn
.ant-dropdown-trigger {
  font-family: inherit;
  font-size: var(--ant-font-size);
  line-height: var(--ant-line-height);
  color: var(--ant-color-text);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: var(--ant-control-height);
  padding: 0 calc(var(--ant-padding-sm) - 1px);
  background-color: var(--g-select-background-color);
  border: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border);
  border-radius: var(--ant-border-radius);

  &:hover {
    color: var(--ant-color-text);
    border-color: var(--ant-color-primary-hover);
  }

  .anticon.anticon-down svg {
    width: 12px;
  }
}

.ant-dropdown {
  border-radius: 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
  padding-inline-end: 8px;
}

.dropdown-with-collapse {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);

  .ant-collapse {
    border-width: 1px 0 1px 0;
    border-radius: 0;
  }

  .dropdown-row-group {
    flex-direction: column;
    width: 100%;

    .dropdown-row {
      padding-left: 45px;

      + .dropdown-row {
        border-top: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border);
      }
    }
  }

  .ant-collapse .ant-collapse-content>.ant-collapse-content-box,
  .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse>.ant-collapse-item:last-child, .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 0;
  }

  .ant-collapse-header {
    background-color: var(--g-select-background-color);
  }
}

.dropdown-row {
  width: 100%;
  padding: 12px 12px 12px 24px;
  background-color: var(--g-select-background-color);

  .ant-checkbox {
    margin-left: auto;

    + span {
      padding: 0;
    }
  }
}

.ant-dropdown .ant-dropdown-menu {
  max-height: 300px;
  padding: 0;
  border-radius: 0;
  overflow: auto;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0;
}

.ant-dropdown-menu-item + .ant-dropdown-menu-item {
  border-top: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border);
}

// Checkbox
.ant-checkbox .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  --ant-control-interactive-size: 18px;

  border-width: 2px;
  border-radius: 2px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
  border-color: var(--g-accent-color);

  &::after {
    border-color: var(--g-accent-color);
  }
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--g-accent-color);
  background-color: transparent;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: var(--g-accent-color);
}

// Progress
.ant-progress {
  display: flex;
  align-items: center;
}

// Menu
.ant-menu {
  &.collapse-menu {
    --ant-menu-item-border-radius: 4px;
    --ant-menu-sub-menu-item-bg: transparent;

    border-inline-end: 0 none !important;

    .ant-menu-submenu-title, .ant-menu-item {
      margin: 0;
      border-bottom: 1px solid var(--g-devider-color);
    }

    .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
      inset-inline-start: var(--ant-margin);
    }

    .ant-menu-submenu-title {
      padding-left: 44px !important;
      background-color: #E5F6FD;
    }
  }
}

.ant-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.custom-collapse-panel.custom-collapse-panel {
  --ant-collapse-header-padding: 12px 24px;

  background: #ffffff;
  border: 0;

  &.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;

    .ant-collapse-content-box {
      padding: 0 var(--g-board-padding-inline);
    }
  }

  .ant-collapse-content-box .board.full-width-body {
    margin-inline: calc(var(--g-board-padding-inline) * -1);
  }

  &.ant-collapse &.ant-collapse h2 {
    font-size: 28px;
    font-weight: 300;
  }

  &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
    padding-inline-end: 8px;
    margin-top: 25px;
  }

  &.ant-collapse > .ant-collapse-item {
    border-bottom: 1rem solid #f8f8f8;
  }

  &.ant-collapse .ant-collapse-content {
    border: 0;
  }
}

// Form controls
.ant-input, .ant-select {
  height: var(--g-form-control-height);
}

.ant-btn-icon-only {
  border-color: transparent;
}