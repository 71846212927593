.auth-dialog-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-block: var(--g-auth-layout-padding-top) 56px;
  padding-inline: 16px;
  background-image: url("../../../public/img/auth-background-gradient.png");
  background-size: cover;

  @include breakpoint(1024) {
    background-image: url("../../../public/img/auth-background.png");
    background-color: rgb(210, 231, 242);
    background-blend-mode: multiply;
  }
}