.vl-form-group {
  display: flex;

  &.is-vertical {
    flex-direction: column;

    .vl-label {
      margin-bottom: 8px;
    }
  }
}